/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Apply a basic font and background color */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
    padding: 1rem; /* Add padding to prevent content from touching edges */
}

/* Center content on the page */
.container {
    max-width: 1200px; /* Limit container width */
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
}

/* Heading styles */
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
    color: #333;
}

/* Style for links */
a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Button styles */
button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition */
}

button:hover {
    background-color: #0056b3;
}

/* Form elements */
input[type="text"],
input[type="password"],
textarea {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem; /* Ensure consistent font size */
}

/* Basic container for forms and other components */
.form-container {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px; /* Limit the width of the form container */
    margin: 0 auto; /* Center the form container horizontally */
}

/* Flexbox utility classes */
.flex {
    display: flex;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

/* Responsive styling */
@media (max-width: 768px) {
    .container {
        padding: 0.5rem;
    }
    
    .form-container {
        padding: 1.5rem;
        margin: 0 1rem; /* Add some margin for mobile devices */
    }
}
